@import 'src/styles/variables';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;

  padding-bottom: 8px;

  @media screen and (max-width: $breakpoint-desktop) {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}

.common-badge-styles {
  white-space: nowrap;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  border: 1px solid var(--color-D10);
  height: 36px;
}

.badge,
.badge a,
.badgeActive {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-D100);
}

.badge {
  @extend .common-badge-styles;
  background: var(--color-D10);
}

.badgeActive {
  @extend .common-badge-styles;
  border-color: var(--color-B30);
  background-color: var(--color-B06);
  color: var(--color-B100);
}
