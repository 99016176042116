.root {
    color: var(--color-D20);
}

.high {
    color: var(--color-G100);
}

.medium {
    color: var(--color-O100)
}

.low {
    color: var(--color-R100)
}

.valueIcon {
    margin-left: 10px;
    color: var(--color-D20);
}
