@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;
  padding: calc($grid_gap / 2);

  @include base-card;

  @media screen and (max-width: 400px) {
    padding: calc($grid_gap_mobile / 2);
  }

  &.clickable {
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}
