.logo {
  border: 1px solid var(--color-D06);
  background: var(--color-L100);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}

.badge {
  & > span {
    box-shadow: none ;
    background-color: inherit;
  }
}
